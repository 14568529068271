import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function CountryRoad() {
  return (
    <Layout>
      <Helmet title="Country Road | James Taylor" />

      <h1>Country Road</h1>

      <h2>James Taylor</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>Take to the highway won't you lend me your name</p>
          <p>Your way and my way seem to be one and the same</p>
          <p>Mama don't understand it</p>
          <p>She wants to know where I've been</p>
          <p>I'd have to be some kind of natural born fool</p>
          <p>To want to pass that way again</p>
        </Verse>
        <Chorus>
          <p>But you know I could feel it</p>
          <p>On a country road</p>
        </Chorus>
        <Verse>
          <p>Sail on home to Jesus won't you good girls and boys</p>
          <p>I'm all in pieces, you can have your own choice</p>
          <p>But I can hear a heavenly band full of angels</p>
          <p>And they're coming to set me free</p>
          <p>I don't know nothing 'bout the why or when</p>
          <p>But I can tell that it's bound to be</p>
        </Verse>
        <Chorus>
          <p>Because I could feel it, child, yeah</p>
          <p>On a country road</p>
        </Chorus>
        <Bridge>
          <p>I guess my feet know where they want me to go</p>
          <p>Walking on a country road</p>
        </Bridge>
        <Verse>
          <p>Take to the highway won't you lend me your name</p>
          <p>Your way and my way seem to be one and the same, child</p>
          <p>Mama don't understand it</p>
          <p>She wants to know where I've been</p>
          <p>I'd have to be some kind of natural born fool</p>
          <p>I wanna pass that way again</p>
        </Verse>
        <Chorus>
          <p>But I could feel it, oh</p>
          <p>On a country road</p>
          <p>Walk on down, walk on down, walk on down</p>
          <p>Walk on down, walk on down a country road</p>
          <p>La la la la la la la la la la la la la la la la la la la</p>
          <p>Country road, oh, yeah</p>
          <p>Walking on a country road</p>
          <p>Country road, uh</p>
          <p>Country road</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
